<template>
  <div class="min-h-screen">
    <NavBar />

    <!-- Page Content -->
    <main class="bg-pearl">
      <slot />
    </main>

    <Footer />

    <a href="https://wa.me/+972506961220" target="_blank" class="bg-whatsapp text-white fixed bottom-0 right-0 mr-3 mb-3 lg:mr-5 lg:mb-5 rounded-full z-50">
      <svg-icon name="social/whatsapp" class="h-6 w-6 m-4" />
    </a>
    
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const isFixed = ref(false);
const navbar = ref(null);
const animateUp = ref(false);
const animateDown = ref(false);
const menuItems = ref([
    {
        label: "Services",
        route: null,
        children: [
            {
                label: "Our Services",
                route: "/services",
            },
            {
                label: "Web Development",
                route: "/services/web-development",
            },
            {
                label: "Software Development",
                route: "/services/software-development",
            },
            {
                label: "Mobile Development",
                route: "/services/mobile-development",
            },
            {
                label: "DevOps Consulting",
                route: "/services/devops-consulting",
            },
            {
                label: "Big Data",
                route: "/services/big-data",
            }
        ],
    },
    {
        label: "Solutions",
        route: null,
        children: [
            {
                label: "Solution for Agencies",
                route: "/solutions/agencies",
            },
            {
                label: "Solution for Startups",
                route: "/solutions/startups",
            },
            {
                label: "Solution for Businesses",
                route: "/solutions/businesses",
            },
            {
                label: "Solution for Enterprise",
                route: "/solutions/enterprises",
            },
        ],
    },
    {
        label: "Company",
        route: "/about",
    },
    {
        label: "Case studies",
        route: "/case-study",
    },
    {
        label: "Blog",
        route: "/blog",
    },
    {
        label: "Careers",
        route: "/careers",
    },
    {
        label: "Nearshore",
        route: "/nearshore",
    },
]);

let lastScrollTop = 0;
let navbarHeight = 0;
let handleScroll = () => { };

onMounted(() => {
    navbarHeight = navbar.value.offsetHeight;
    const handleScroll = () => {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
            // Down scroll code

            if (st > navbarHeight) {
                // Lower than navbar
                animateUp.value = true;
                animateDown.value = false;
            }
        } else {
            // Up scroll code

            if (st > navbarHeight) {
                isFixed.value = true;
                animateDown.value = true;
                animateUp.value = false;
            } else {
                animateUp.value = false;
                animateDown.value = false;
            }
        }

        if (st === 0) {
            isFixed.value = false;
            animateDown.value = false;
            animateUp.value = false;
        }
        lastScrollTop = st <= 0 ? 0 : st;
    };

    window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
    <nav class="w-full z-40 navbar" ref="navbar" :class="{
        fixed: isFixed,
        animateUp: animateUp,
        animateDown: animateDown,
    }">
        <div class="hidden lg:block">
            <NavBarDesktop :menuItems="menuItems" />
        </div>
        <div class="block lg:hidden">
            <NavBarMobile :menuItems="menuItems" />
        </div>
    </nav>
</template>

<script setup></script>

<template>
  <div class="bg-black text-white pt-16">
    <SectionSep dark />
    <section
      class="container px-5 md:px-7 lg:px-14 py-10 grid grid-cols-4 md:grid-cols-12 gap-4"
    >
      <div class="col-span-4 md:col-span-12 lg:col-span-5">
        <h2>
          <SplitAnimate :duration="0.4" text="Let’s Talk." />
        </h2>
        <div class="mt-10 mb-4">
          <Button severity="tertiary" arrow :url="'/contact'"
            >Contact Us</Button
          >
        </div>
        <BookFreeCall />
      </div>
      <div
        class="col-span-4 md:col-span-5 lg:col-span-4 mt-16 md:mt-10 lg:mt-0"
      >
        <h3>Services</h3>
        <div class="lg:flex mt-6">
          <div class="lg:pe-10">
            <div class="text-white60 mt-3">
              <NuxtLink :to="'/services/web-development'"
                >Web Development</NuxtLink
              >
            </div>
            <div class="text-white60 mt-3">
              <NuxtLink :to="'/services/software-development'"
                >Software Development</NuxtLink
              >
            </div>
            <div class="text-white60 mt-3">
              <NuxtLink :to="'/services/mobile-development'"
                >Mobile Development</NuxtLink
              >
            </div>
          </div>
          <div>
            <div class="text-white60 mt-3">
              <NuxtLink :to="'/services/devops-consulting'"
                >DevOps Consulting</NuxtLink
              >
            </div>
            <div class="text-white60 mt-3">
              <NuxtLink :to="'/services/big-data'">Big Data</NuxtLink>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2 md:col-span-4 lg:col-span-2 mt-10 lg:mt-0">
        <h3>Solutions</h3>
        <div class="mt-6">
          <div class="text-white60">
            <NuxtLink :to="'/solutions/agencies'">For Agencies</NuxtLink>
          </div>
          <div class="text-white60 mt-3">
            <NuxtLink :to="'/solutions/startups'">For Startups</NuxtLink>
          </div>
          <div class="text-white60 mt-3">
            <NuxtLink :to="'/solutions/businesses'">For Businesses</NuxtLink>
          </div>
          <div class="text-white60 mt-3">
            <NuxtLink :to="'/solutions/enterprises'">For Enterprises</NuxtLink>
          </div>
        </div>
      </div>
      <div class="col-span-2 md:col-span-3 lg:col-span-1 mt-10 lg:mt-0">
        <h3>Other</h3>
        <div class="mt-6">
          <div class="text-white60">
            <NuxtLink :to="'/about'">About us</NuxtLink>
          </div>
          <div class="text-white60 mt-3">
            <NuxtLink :to="'/blog'">Blog</NuxtLink>
          </div>
          <div class="text-white60 mt-3">
            <NuxtLink :to="'/careers'">Careers</NuxtLink>
          </div>
        </div>
      </div>
    </section>
    <SectionSep dark />
    <section
      class="container px-5 md:px-7 lg:px-14 py-6 md:flex justify-between"
    >
      <div class="flex flex-col md:flex-row justify-center items-center">
        <div class="shrink-0 flex items-center w-100">
          <ApplicationMark secondary class="block h-4 w-auto" />
        </div>
        <span class="text-white60 md:ps-4 mt-4 md:mt-0"
          >©2023. All Rights Reserved</span
        >
      </div>
      <div class="flex md:block justify-center mt-4 md:mt-0">
        <span class="text-white60">
          <NuxtLink :to="'/privacy'">Privacy</NuxtLink>
        </span>
        <span class="text-white60 ps-6">
          <NuxtLink :to="'/terms'">Terms</NuxtLink>
        </span>
        <span class="text-white60 ps-6">
          <NuxtLink :to="'/privacy'">Cookies</NuxtLink>
        </span>
      </div>
    </section>
  </div>
</template>

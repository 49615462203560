<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
const route = useRoute();
const currentRoute = route.name;

const nav = ref("home");

const props = defineProps({
  menuItems: {
    type: Array,
    default: [],
  },
});

const isNavOpen = ref(false);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const handleScroll = () => {
  isNavOpen.value = false;
  nav.value = "home";
};

const toggleMenu = () => {
  isNavOpen.value = !isNavOpen.value;
  nav.value = "home";
};
</script>

<template>
  <!-- Primary Navigation Menu -->
  <div class="">
    <div
      class="border-black border-solid border-b z-40 translate-y-0 relative bg-white"
    >
      <div class="ps-6 pe-7 flex justify-between items-center h-16">
        <!-- Logo -->
        <div class="shrink-0 flex items-center">
          <NuxtLink to="/" aria-label="home">
            <ApplicationMark class="block h-9 w-auto" />
          </NuxtLink>
        </div>

        <!-- Hamburger -->
        <div class="-mr-2 flex items-center pt-2 pb-3 space-y-1">
          <button
            aria-label="navbar"
            class="inline-flex items-center justify-center p-2 rounded-md text-black focus:outline-none active:text-jade transition duration-150 ease-in-out"
            @click="toggleMenu"
          >
            <svg
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.33334 16C0.95556 16 0.638671 15.872 0.382671 15.616C0.126671 15.36 -0.000884275 15.0436 4.61361e-06 14.6667C4.61361e-06 14.2889 0.128005 13.972 0.384005 13.716C0.640005 13.46 0.956449 13.3324 1.33334 13.3333H22.6667C23.0444 13.3333 23.3613 13.4613 23.6173 13.7173C23.8733 13.9733 24.0009 14.2898 24 14.6667C24 15.0444 23.872 15.3613 23.616 15.6173C23.36 15.8733 23.0436 16.0009 22.6667 16H1.33334ZM1.33334 9.33334C0.95556 9.33334 0.638671 9.20534 0.382671 8.94934C0.126671 8.69334 -0.000884275 8.37689 4.61361e-06 8C4.61361e-06 7.62223 0.128005 7.30534 0.384005 7.04934C0.640005 6.79334 0.956449 6.66578 1.33334 6.66667H22.6667C23.0444 6.66667 23.3613 6.79467 23.6173 7.05067C23.8733 7.30667 24.0009 7.62312 24 8C24 8.37778 23.872 8.69467 23.616 8.95067C23.36 9.20667 23.0436 9.33423 22.6667 9.33334H1.33334ZM1.33334 2.66667C0.95556 2.66667 0.638671 2.53867 0.382671 2.28267C0.126671 2.02667 -0.000884275 1.71023 4.61361e-06 1.33334C4.61361e-06 0.95556 0.128005 0.638672 0.384005 0.382672C0.640005 0.126672 0.956449 -0.000884275 1.33334 4.6136e-06H22.6667C23.0444 4.6136e-06 23.3613 0.128005 23.6173 0.384005C23.8733 0.640005 24.0009 0.956449 24 1.33334C24 1.71112 23.872 2.028 23.616 2.284C23.36 2.54 23.0436 2.66756 22.6667 2.66667H1.33334Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Responsive Navigation Menu -->
    <transition name="slide-fade" key="open" class="nav-links bg-white">
      <div v-if="isNavOpen">
        <div
          class="px-5 pt-10 transition duration-300 ease-in-out z-30"
          v-show="nav === 'home'"
        >
          <template v-for="(menuItem, index) in menuItems" :key="index">
            <span class="block text-2xl text-black font-medium mb-8">
              <NuxtLink
                v-if="!menuItem.children"
                @click="isNavOpen = false"
                :class="{
                  'text-jade': currentRoute === menuItem.route,
                }"
                :to="menuItem.route"
              >
                {{ menuItem.label }}
              </NuxtLink>
              <span class="flex items-center" v-else>
                <span
                  @click="nav = menuItem.label"
                  class="cursor-pointer me-2"
                  >{{ menuItem.label }}</span
                >
                <span class="">
                  <svg-icon name="common/plus" class="h-5 w-5" />
                </span>
              </span>
            </span>
          </template>
        </div>

        <!-- Sub menu -->
        <div
          class="transition duration-300"
          :class="{
            block: isNavOpen,
            hidden: !isNavOpen,
          }"
        >
          <div v-for="(menuItem, index) in menuItems" :key="index">
            <div
              v-if="menuItem.children"
              :ref="menuItem.label"
              v-show="nav === menuItem.label"
            >
              <div class="flex text-jade items-center mx-6 mb-6 mt-4">
                <div
                  class="text-jade cursor-pointer me-2"
                  @click="nav = 'home'"
                >
                  <svg-icon name="common/x" class="h-3 w-3" />
                </div>
                <span>{{ menuItem.label }}</span>
              </div>
              <NuxtLink
                v-for="(child, j) in menuItem.children"
                :key="j"
                :to="child.route"
                :active="currentRoute === child.route"
                @click="isNavOpen = false"
                class="block description text-black font-medium mb-8 mx-12"
              >
                {{ child.label }}
              </NuxtLink>
            </div>
          </div>
        </div>

        <div class="px-5 my-6">
          <div class="h-1 border-b border-solid border-black16 my-6"></div>

          <div class="flex justify-between items-center">
            <div class="flex">
              <a taget="_blank" class="me-4" href="https://www.linkedin.com/company/trez-labs">
                <svg-icon name="social/linkedin" class="h-5 w-5" />
              </a>

              <!-- <a taget="_blank" class="" href="https://www.twitter.com">
                <svg-icon name="social/twitter" class="h-5 w-5" />
              </a> -->
            </div>
            <Button url="/contact" secondary arrow>Contact</Button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
